import M$plugin$BackImgLayer from './facade/js/backimglayer';
import M$control$BackImgLayerControl from './facade/js/backimglayercontrol';
import M$impl$control$BackImgLayerControl from './impl/ol/js/backimglayercontrol';

if (!window.M.plugin) window.M.plugin = {};
if (!window.M.control) window.M.control = {};
if (!window.M.impl) window.M.impl = {};
if (!window.M.impl.control) window.M.impl.control = {};
window.M.plugin.BackImgLayer = M$plugin$BackImgLayer;
window.M.control.BackImgLayerControl = M$control$BackImgLayerControl;
window.M.impl.control.BackImgLayerControl = M$impl$control$BackImgLayerControl;
